!(function(e) {
    "use strict";
    try {
        var i = e(".js-pagepilling"),
            o = e("#js-pageinfo"),
            n = o.find(".page-info__title"),
            a = e(".header-page-pilling"),
            l = e(window).width(),
            t = e(".js-line");
        l > 1500 &&
            (i.each(function() {
                e(this).pagepiling({
                    menu: null,
                    direction: "vertical",
                    verticalCentered: !0,
                    sectionsColor: [],
                    anchors: ["intro","burot","spotlight","over-ons","contact"],
                    scrollingSpeed: 550,
                    easing: "ease-in-out",
                    loopBottom: !1,
                    loopTop: !1,
                    css3: !0,
                    navigation: { position: "right" },
                    normalScrollElements: null,
                    normalScrollElementTouchThreshold: 5,
                    touchSensitivity: 5,
                    keyboardScrolling: !0,
                    sectionSelector: ".section-pp",
                    animateAnchor: !1,
                    afterRender: function() {
                        e("#pp-nav").addClass("pp-bullet");
                    },
                    onLeave: function(i, l, s) {
                        var r = e(".page-pagepiling-wrap .section-pp").eq(
                                l - 1
                            ),
                            c = r.data("background"),
                            g = r.data("title");
                        o.removeClass("light dark").addClass(c),
                            e("#pp-nav")
                                .removeClass("light dark")
                                .addClass(c),
                            n.text(g),
                            a.removeClass("light dark").addClass(c),
                            "light" === c
                                ? a
                                      .find(".logo img")
                                      .attr("src", "images/icon/logo-black.png")
                                : a
                                      .find(".logo img")
                                      .attr(
                                          "src",
                                          "images/icon/logo-white.png"
                                      ),
                            4 == l && t.addClass("active");
                    }
                });
            }),
            e(".js-mouse-wheel").on("click", function(i) {
                e.fn.pagepiling.moveSectionDown(), i.preventDefault();
            }));
    } catch (e) {
        console.log(e);
    }
})(jQuery);
