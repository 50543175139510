try {
    window.Popper = require("popper.js").default;
    window.$ = window.jQuery = require("jquery");

    require("bootstrap");

    //animsition
    require("animsition/dist/js/animsition");

    //slick
    require("slick-slider");

    //waypoints
    require("waypoints/lib/jquery.waypoints.min");

    //wow
    window.WOW = require("wow.js/dist/wow");

    //counterup
    require("counterup/jquery.counterup");

    //isotope
    window.Isotope = require("isotope-layout");

    //matchHeight
    require("jquery-match-height");

    //select2
    require("select2");

    //sweetalert
    require("sweetalert2");

    //no ui slider
    require("nouislider");

    //jQuery validate
    require("jquery-validation/dist/jquery.validate");

    //pagepiling
    require("pagepiling.js");
    require("../js/config/config-pagePiling.min");

    // Maginific popup
    require("magnific-popup/dist/jquery.magnific-popup");
} catch (e) {}
